import "./initial-config";

import { ApplicationConfigurations, setAppConfigurations } from "@mongez/react";
import { setPreloadConfiguration } from "@mongez/react-utils";
import "app/utils/locales";
import sa from "assets/images/flags/sa.png";
import uk from "assets/images/flags/uk.png";
import LoadingErrorHandler from "design-system/components/Indicators/LoadingErrorHandler";
import Root from "design-system/layouts/Root";
import "react-datepicker/dist/react-datepicker.css";
import "react-phone-input-2/lib/style.css";
import "react-toastify/ReactToastify.min.css";
import "./index.scss";

setPreloadConfiguration({
  loadingErrorComponent: LoadingErrorHandler,
  cache: {
    // expiresAfter: 60 * 5,
    expiresAfter: 0,
  },
});

const appConfigurations: ApplicationConfigurations = {
  localization: {
    defaultLocaleCode: process.env.REACT_APP_DEFAULT_LOCALE_CODE,
    fallback: process.env.REACT_APP_FALLBACK_LOCALE_CODE,
    locales: {
      en: {
        direction: "ltr",
        name: "English",
        flag: uk,
      },
      ar: {
        direction: "rtl",
        name: "اللغة العربية",
        flag: sa,
      },
    },
  },
  helmet: {
    appName: process.env.REACT_APP_NAME,
    appendAppName: true,
    appNameSeparator: " | ",
    translatable: true,
    translateAppName: true,
  },
  router: {
    basePath: process.env.REACT_APP_PRODUCTION_BASE_PATH,
    notFound: {
      mode: "redirect",
      route: "/404",
    },
    rootComponent: Root,
    // preloader: Preloader,
  },
};

setAppConfigurations(appConfigurations);
