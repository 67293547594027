import styled from "@emotion/styled";
import { getCurrentLocaleCode, trans } from "@mongez/localization";
import { navigateBack } from "@mongez/react-router";
import { BsInbox } from "react-icons/bs";
import { IoMdArrowRoundBack, IoMdArrowRoundForward } from "react-icons/io";
import { theme } from "..";
import { MainButton } from "./Button";
import { Flex } from "./Grid";

export * from "./Badge";
export * from "./Button";
export * from "./Containers";
export * from "./Form/Custom";
export * from "./Grid";
export * from "./Icons";
export * from "./Images";
export * from "./Typography";

export const EmptyContentContainer = styled("div")({
  // minHeight: "30vh",
  width: "100%",
  display: "flex",
  gap: "10px",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  fontFamily: "GraphikArabic",
  fontWeight: "regular",
  fontSize: "22px",
  color: theme.colors.gray[200],
  overflowWrap: "anywhere",
});

export type EmptyContentProps = {
  message?: string;
  backButton?: boolean;
  icon?: React.FC<any>;
  navigate?: any;
};

export const EmptyContent = ({
  message,
  icon: Icon,
  navigate = navigateBack,
  backButton = true,
}: EmptyContentProps) => {
  return (
    <EmptyContentContainer>
      {(Icon && <Icon size={80} color={theme.colors.gray[200]} />) || (
        <BsInbox size={80} color={theme.colors.gray[200]} />
      )}

      {trans(message || "noContent")}

      {backButton && (
        <MainButton
          color="teal"
          style={{ marginTop: "20px" }}
          onClick={navigate}>
          <Flex alignItems="center">
            {getCurrentLocaleCode() === "ar" ? (
              <IoMdArrowRoundForward />
            ) : (
              <IoMdArrowRoundBack />
            )}

            {trans("goBack")}
          </Flex>
        </MainButton>
      )}
    </EmptyContentContainer>
  );
};

export const SiteLogo = styled("img")({
  width: "185px",
  objectFit: "cover",
});
