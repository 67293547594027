import styled from "@emotion/styled";
import { theme } from "../../..";

export const FileInputContainer = styled("div")({
  width: "80px",
  height: "80px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  borderRadius: "10px",
  backgroundColor: theme.colors.gray.inputs,
});

export const FileInputContainerFluid = styled("div")({
  backgroundColor: theme.colors.gray.inputs,
  color: theme.colors.gray[400],
  border: 0,
  outline: 0,
  minHeight: "60px",
  fontSize: "24px",
  lineHeight: "29.7px",
  padding: "10px 25px",
  borderRadius: "10px",
  maxWidth: "100%",
  display: "flex",
  alignItems: "center",

  "&::placeholder": {
    color: theme.colors.gray[400],
  },
});
