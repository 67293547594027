import styled from "@emotion/styled";
import { FlexRowType } from "../types";

export const FlexBetween = styled("div")`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

export const FlexBetweenFullWidth = styled("div")`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

export const FlexColumn = styled("div")`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 15px;
`;

export const FlexRow = styled("div")`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 15px;
`;

export const Flex = styled("div")<FlexRowType>(
  {
    display: "flex",
  },
  ({
    justifyItems,
    justifyContent,
    justifySelf,
    alignSelf,
    flexDirection,
    flexGrow,
    alignItems,
    alignContent,
    gap,
    fullWidth,
    flexWrap,
    maxWidth,
    color,
    xs,
    sm,
    md,
    lg,
    xl,
    xxl,
    hd,
    onRTL,
    onLTR,
  }) => ({
    flexDirection: flexDirection || "row",
    justifyContent: justifyContent || "initial",
    justifyItems: justifyItems || "initial",
    justifySelf: justifySelf || "initial",
    alignSelf: alignSelf || "initial",
    alignItems: alignItems || "initial",
    alignContent: alignContent || "initial",
    gap: gap || "15px",
    flexGrow: fullWidth ? "1" : flexGrow || "initial",
    width: fullWidth || maxWidth ? "100%" : "initial",
    flexWrap: flexWrap || "wrap",
    maxWidth: maxWidth || "initial",
    color: color || undefined,
    "@media screen and (max-width: 280px)": { ...xs } || {},
    "@media screen and (max-width: 576px)": { ...sm } || {},
    "@media screen and (max-width: 768px)": { ...md } || {},
    "@media screen and (max-width: 992px)": { ...lg } || {},
    "@media screen and (max-width: 1200px)": { ...xl } || {},
    "@media screen and (min-width: 1360px)": { ...xxl } || {},
    "@media screen and (min-width: 1900px)": { ...hd } || {},

    "html[dir=rtl] &": { ...onRTL } || {},
    "html[dir=ltr] &": { ...onLTR } || {},
  }),
);
