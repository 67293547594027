import styled from "@emotion/styled";
import logo from "../../../utils/logo";
import { Flex } from "../Grid";
import { MainIcon } from "../Icons";

export const LoaderIcon = styled(MainIcon)({
  animation: "zoom 2.3s ease-in-out infinite",
  transition: "all 0.3s ease-in-out",
  transform: "scale(1) translate(0, 0)",

  "@keyframes zoom": {
    "0%": {
      transform: "scale(1) translate(0, 0)",
    },
    "50%": {
      transform: "scale(1.2) translate(-20px, -50px)",
    },
  },
});

export default function LoaderOverlay() {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      fullWidth
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        height: "100vh",
        backgroundColor: "#000000d9",
        zIndex: "9999",
      }}>
      <LoaderIcon size="250" src={logo()} />
    </Flex>
  );
}
