import styled from "@emotion/styled";
import { Autocomplete } from "@mantine/core";
import { trans } from "@mongez/localization";
import { FormInputProps, HiddenInput, useFormInput } from "@mongez/react-form";
import { useEffect } from "react";
import { theme } from "../..";
import { Flex, LightGraySpan } from "../styles";
import InputError from "./InputError";
import InputLabel from "./InputLabel";
import { PhoneInputComp } from "./PhoneInputComp";
import { UploadInputComp, UploadInputFluidComp } from "./UploadInputComp";
const Input = styled.input`
  label: Input;
  &:read-only {
    background-color: ${theme.colors.gray["inputs"]};
    cursor: not-allowed;
  }
`;

export default function BaseInput(props: FormInputProps) {
  const {
    name,
    id,
    type,
    value,
    setValue,
    label,
    placeholder,
    required,
    onChange,
    onBlur,
    error,
    otherProps,
  } = useFormInput(props);

  useEffect(() => {
    if (props.value !== undefined) {
      setValue(props.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  return (
    <Flex
      flexDirection="column"
      gap="5px"
      className="form-control"
      fullWidth={props.fullWidth !== undefined ? props?.fullWidth : true}
      style={{ ...props?.containerStyles }}>
      {label && (
        <InputLabel htmlFor={id} required={required}>
          <LightGraySpan>{trans(label as string)}</LightGraySpan>
        </InputLabel>
      )}

      {type === "textarea" ? (
        <textarea
          id={id}
          name={name}
          placeholder={trans(placeholder as string)}
          onChange={onChange}
          onBlur={onBlur as any}
          value={value}
          {...otherProps}
          style={{
            border: `1px solid ${
              error ? theme.colors.primary.red : "transparent"
            }`,
            ...props?.style,
          }}></textarea>
      ) : type === "tel" ? (
        <>
          <HiddenInput name={name} value={value.replace(" ", "")} />

          <PhoneInputComp
            id={id}
            name={""}
            placeholder={trans(placeholder as string)}
            onChange={setValue}
            onBlur={onBlur as any}
            value={value}
            {...otherProps}
            style={{
              border: `1px solid ${
                error ? theme.colors.primary.red : "transparent"
              }`,
              ...props?.style,
            }}
          />
        </>
      ) : type === "file" ? (
        props?.fluid ? (
          <UploadInputFluidComp
            id={id}
            name={name}
            placeholder={trans(placeholder as string)}
            onChange={onChange}
            onBlur={onBlur as any}
            value={value}
            defaultValue={props?.defaultValue}
            {...otherProps}
            style={{
              border: `1px solid ${
                error ? theme.colors.primary.red : "transparent"
              }`,
              ...props?.style,
            }}
          />
        ) : (
          <UploadInputComp
            id={id}
            name={name}
            placeholder={trans(placeholder as string)}
            setValue={setValue}
            onBlur={onBlur as any}
            value={value}
            defaultURL={props?.defaultURL}
            defaultValue={props?.defaultValue}
            {...otherProps}
            style={{
              border: `1px solid ${
                error ? theme.colors.primary.red : "transparent"
              }`,
              ...props?.style,
            }}
          />
        )
      ) : type === "autoComplete" ? (
        <Autocomplete
          id={id}
          name={name}
          placeholder={trans(placeholder as string)}
          onChange={setValue}
          onBlur={onBlur as any}
          value={value}
          {...otherProps}
          data={props?.data || []}
          style={{
            border: `1px solid ${
              error ? theme.colors.primary.red : "transparent"
            }`,
            ...props?.style,
          }}
        />
      ) : (
        <Input
          id={id}
          type={type}
          name={name}
          placeholder={trans(placeholder as string)}
          onChange={onChange}
          onBlur={onBlur as any}
          value={value || props.value}
          {...otherProps}
          style={{
            border: `1px solid ${
              error ? theme.colors.primary.red : "transparent"
            }`,
            ...props?.style,
          }}
        />
      )}

      {error && <InputError error={error} />}
    </Flex>
  );
}
