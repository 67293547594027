import { trans } from "@mongez/localization";
import { CustomPhoneInput } from "./styles";

export const PhoneInputComp = (props: any) => {
  return (
    <CustomPhoneInput
      name={props?.name}
      country={"sa"}
      enableAreaCodes={true}
      // enableAreaCodeStretch
      placeholder={trans(props?.placeholder || "enterPhoneNumber")}
      value={props?.value}
      onChange={props?.onChange}
      {...props}
    />
  );
};
