import { extend, groupedTranslations } from "@mongez/localization";
import { arTranslation, enTranslation } from "@mongez/validator";

extend("en", enTranslation);
extend("ar", arTranslation);

// Add only common localization
groupedTranslations({
  loading: {
    en: "Loading...",
    ar: "جار التحميل...",
  },
  somethingWentWrong: {
    en: "Something Went wrong, please try again later.",
    ar: "حدث خطأ ما, من فضلك حاول مرة أخرى في وقت لاحق.",
  },
  errorHeading: {
    en: "Error!",
    ar: "خطأ!",
  },
  accessDenied: {
    en: "Access Denied!",
    ar: "غير مصرح لك بدخول هذه الصفحة!",
  },
  notFoundPage: {
    en: "Not Found Page",
    ar: "الصفحة المطلوبة غير موجودة",
  },
  // Header
  signUp: {
    en: "Sign Up",
    ar: "سجل الأن",
  },
  signup: {
    en: "Sign Up",
    ar: "سجل الأن",
  },
  login: {
    en: "Login",
    ar: "تسجيل الدخول",
  },
  changeLanguage: {
    en: "Change Language",
    ar: "تغير اللغة",
  },
  search: {
    en: "Search",
    ar: "البحث",
  },
  home: {
    en: "Home",
    ar: "الرئيسية",
  },
  courses: {
    en: "Courses",
    ar: "الدورات",
  },
  trainingNeeds: {
    en: "Training Needs",
    ar: "الإحتياجات التدريبية",
  },
  support: {
    en: "Support",
    ar: "الدعم الفني",
  },
  faq: {
    en: "FAQ",
    ar: "الأسئلة الشائعة",
  },
  usagePolicy: {
    en: "Usage Policy",
    ar: "سياسة الإستخدام",
  },
  help: {
    en: "Help",
    ar: "المساعدة",
  },
  organizationalChart: {
    en: "Organizational Chart",
    ar: "الهيكل التنظيمي",
  },
  contactUs: {
    en: "Contact Us",
    ar: "تواصل معنا",
  },
  forContactUs: {
    en: "Contact Us",
    ar: "للتواصل معنا",
  },
  realMainAddress: {
    en: "15 King Fahd Street, Riyadh, Saudi Arabia",
    ar: "شارع الملك فهد ، الرياض ، السعودية 15",
  },
  more: {
    en: "More",
    ar: "المزيد",
  },
  aboutUs: {
    en: "About Us",
    ar: "من نحن",
  },
  ourMessage: {
    en: "Our Message",
    ar: "رسالتنا",
  },
  ourMorals: {
    en: "Our Values",
    ar: "قيمنا",
  },
  ourVision: {
    en: "Our Vision",
    ar: "رؤيتنا",
  },
  "?": {
    en: "?",
    ar: "؟",
  },
  details: {
    en: "Details",
    ar: "تفاصيل",
  },
  subscribe: {
    en: "Subscribe",
    ar: "إشترك",
  },
  theSubscribe: {
    en: "Subscribe",
    ar: "الإشترك",
  },
  services: {
    en: "Services",
    ar: "خدماتنا",
  },
  latestCourses: {
    en: "Latest Courses",
    ar: "أحدث الدورات",
  },
  offers: {
    en: "Offers",
    ar: "العروض",
  },
  subscribeIn: {
    en: "Subscribe in",
    ar: "اشترك في",
  },
  newsList: {
    en: "our newsletter",
    ar: "نشرتنا الأخبارية",
  },
  enterEmail: {
    en: "Enter your email ...",
    ar: "أدخل بريك الإلكتروني ...",
  },
  supporters: {
    en: "Supporters",
    ar: "الداعمين",
  },
  privacyPolice: {
    en: "Privacy Policy",
    ar: "سياسة الخصوصية",
  },
  allRightsArePreservedFor: {
    ar: "جميع الحقوق محفوظة لـ",
    en: "All rigts are preserved for",
  },
  newVisionTrainingCenter: {
    ar: "مركز مبادرة الرؤيه الجديده للتدريب",
    en: "New Vision Training Center",
  },
  for: {
    ar: "ل",
    en: "for",
  },
  theSiteWorksOnHandheld: {
    ar: "الموقع يعمل على الاجهزة الكفية بدقة عالية",
    en: "The site works on handheld devices with high accuracy",
  },
  designAndImplementation: {
    ar: "تصميم وتنفيذ",
    en: "Design and implementation",
  },
  subscribeInCourse: {
    ar: "الإشتراك في الدورة",
    en: "Subscribe",
  },
  sr: {
    en: "S.R",
    ar: "ر.س",
  },
  invoice: {
    en: "Invoice",
    ar: "الفاتورة",
  },
  tax: {
    en: "Tax",
    ar: "الضريبة",
  },
  taxValue: {
    en: "Tax Value",
    ar: "قيمة الضريبة",
  },
  discountCouponPlaceholder: {
    en: "Do you have a discount coupon?",
    ar: "هل لديك كوبون خصم ؟",
  },
  totalAfterDiscount: {
    en: "Total after discount",
    ar: "الإجمالي بعد الخصم",
  },
  totalWithTax: {
    en: "Total with tax",
    ar: "الإجمالي مع الضريبية",
  },
  discount: {
    en: "Discount",
    ar: "خصم",
  },
  theDiscount: {
    en: "Discount",
    ar: "الخصم",
  },
  apply: {
    en: "Apply",
    ar: "تطبيق",
  },
  pay: {
    en: "Pay",
    ar: "الدفع",
  },
  live: {
    en: "Live",
    ar: "مباشر",
  },
  current: {
    en: "Current",
    ar: "حالية",
  },
  comming: {
    en: "Comming",
    ar: "قادمة",
  },
  active: {
    en: "Current",
    ar: "حالية",
  },
  finished: {
    en: "Ended",
    ar: "منتهية",
  },
  category: {
    en: "Category",
    ar: "التصنيف",
  },
  AccreditationNo: {
    en: "Accreditation No.",
    ar: "رقم الإعتماد",
  },
  targetGroup: {
    en: "Target group",
    ar: "الفئة المستهدفة",
  },
  beginner: {
    en: "Beginner",
    ar: "مبتدئ",
  },
  intermediate: {
    en: "Intermediate",
    ar: "متوسط",
  },
  advanced: {
    en: "Advanced",
    ar: "متقدم",
  },
  videos: {
    en: "Self-paced",
    ar: "توجيه ذاتي",
  },
  zoom: {
    en: "Live",
    ar: "مباشرة",
  },
  offline: {
    en: "Location attendance",
    ar: "حضوري",
  },
  numberOfSubscribers: {
    en: "Number of subscribers",
    ar: "عدد المشتركين",
  },
  creditHours: {
    en: "Credit hours",
    ar: "الساعات المعتمدة",
  },
  hours: {
    en: "hours",
    ar: "ساعة",
  },
  courseStartDate: {
    en: "Course start date",
    ar: "تاريخ بدأ الدورة",
  },
  nameOfTheLecturer: {
    en: "Lecturer",
    ar: "المحاضر",
  },
  numberOfLectures: {
    en: "Number of lectures",
    ar: "عدد المحاضرات",
  },
  courseEndDate: {
    en: "Course end date",
    ar: "تاريخ انتهاء الدورة",
  },
  whatIWillLearnInThisCourse: {
    en: "What will I learn in this course?",
    ar: "ماذا سأتعلم في هذه الدورة؟",
  },
  whatDoINeedToKnowBeforeThisCourse: {
    en: "What do I need to know before this course?",
    ar: "ما الذي أحتاج إلى معرفته قبل هذه الدورة؟",
  },
  courseContent: {
    en: "Course content",
    ar: "محتوي الدورة",
  },
  courseLocation: {
    en: "Course location",
    ar: "مكان الدورة",
  },
  relatedCourses: {
    en: "Related courses",
    ar: "الدورات المشابهة",
  },
  noContent: {
    en: "No content found",
    ar: "لا يوجد محتوي",
  },
  noContents: {
    en: "No contents found",
    ar: "لا يوجد محتويات",
  },
  free: {
    en: "Free",
    ar: "مجانية",
  },
  chooseThePaymentMethodThatSuitsYou: {
    en: "Choose the payment method that suits you",
    ar: "قم بأختيار طريقة الدفع اللتي تناسبك",
  },
  payment: {
    en: "Payment",
    ar: "الدفع",
  },
  centerName: {
    en: "Center name",
    ar: "اسم المركز",
  },
  checkout: {
    en: "Checkout",
    ar: "اتمام الدفع",
  },
  commercialRegistrationNo: {
    en: "Commercial Registration No",
    ar: "رقم السجل التجاري",
  },
  taxNumber: {
    en: "Tax Number",
    ar: "الرقم الضريبي",
  },
  mainAppName: {
    en: "New Vision Initiative Center",
    ar: "مركز مبادرة الرؤية الجديدة",
  },
  bankAccounts: {
    en: "Bank Accounts",
    ar: "الحسابات البنكية",
  },
  next: {
    en: "Next",
    ar: "التالي",
  },
  back: {
    en: "Back",
    ar: "رجوع",
  },
  accountNumber: {
    en: "Account No",
    ar: "رقم الحساب",
  },
  IBANNo: {
    en: "IBAN No.",
    ar: "رقم الأيبان",
  },
  accountHolderName: {
    en: "Account Holder's Name",
    ar: "اسم صاحب الحساب",
  },
  chooseTheBankYouWantToTransferT: {
    en: "Choose the bank you want to transfer to",
    ar: "قم بأختيار البنك اللذي تريد التحويل الية",
  },
  favoriteCourses: {
    en: "Favorite courses",
    ar: "الدورات المفضلة",
  },
  favorite: {
    en: "Favorite",
    ar: "المفضلة",
  },
  address: {
    en: "Address",
    ar: "العنوان",
  },
  landLine: {
    en: "Land Line",
    ar: "هاتف ارضي",
  },
  mobileNumbers: {
    en: "Mobile Number",
    ar: "رقم الجوال",
  },
  whatsapp: {
    en: "Whatsapp",
    ar: "واتساب",
  },
  email: {
    en: "Email",
    ar: "البريد الإلكتروني",
  },
  writeToUs: {
    en: "Write to us",
    ar: "راسلنا",
  },
  name: {
    en: "Name",
    ar: "الاسم",
  },
  namePlaceholder: {
    en: "Enter name",
    ar: "اكتب الاسم",
  },
  emailPlaceholder: {
    en: "Enter email",
    ar: "اكتب البريد الإلكتروني",
  },
  send: {
    en: "Send",
    ar: "إرسال",
  },
  messageTitle: {
    en: "Message title",
    ar: "عنوان الرسالة",
  },
  messageContent: {
    en: "Message Content",
    ar: "محتوي الرسالة",
  },
  ePay: {
    en: "E-Pay",
    ar: "الدفع الإلكتروني",
  },
  enterCardDetails: {
    en: "Enter the details of the card to be paid with",
    ar: "قم بإدخال بيانات الكارت اللذي سيتم الدفع من خلالة",
  },
  paymentCardNumber: {
    en: "Payment card number",
    ar: "رقم بطاقة الدفع",
  },
  cardHoldersName: {
    en: "Card holder's name",
    ar: "اسم صاحب البطاقة",
  },
  saveCardData: {
    en: "Save card data",
    ar: "حفظ بيانات البطاقة",
  },
  completeFromTheContent: {
    en: "Complete from the content",
    ar: "أتممت من المحتوي",
  },
  pageNotFound: {
    en: "Page was not found",
    ar: "الصفحة غير موجودة",
  },
  pageNotFoundSecondTitle: {
    en: "This page could not be found",
    ar: "تعذر العثور على هذه الصفحة",
  },
  forgetPassword: {
    en: "Forget password",
    ar: "نسيت كلمة المرور",
  },
  changePassword: {
    en: "Change password",
    ar: "تغير كلمة المرور",
  },
  save: {
    en: "Save",
    ar: "حفظ",
  },
  cancel: {
    en: "Cancel",
    ar: "إلغاء",
  },
  resetPassword: {
    en: "Reset password",
    ar: "تعيين كلمة المرور",
  },
  enterCurrentPassword: {
    en: "Enter current password",
    ar: "ادخل كلمة المرور الحالية",
  },
  currentPassword: {
    en: "Current password",
    ar: "كلمة المرور الحالية",
  },
  enterNewPassword: {
    en: "Enter new password",
    ar: "ادخل كلمة المرور الجديدة",
  },
  newPassword: {
    en: "New password",
    ar: "كلمة المرور الجديدة",
  },
  password: {
    en: "Password",
    ar: "كلمة المرور",
  },
  confirmPassword: {
    en: "Confirm password",
    ar: "تأكيد كلمة المرور",
  },
  didYouForgetYourPassword: {
    en: "Did you forget your password?",
    ar: "هل نسيت كلمة المرور؟",
  },
  enterYourEmail: {
    en: "Enter your email",
    ar: "ادخل البريد الأليكتروني الخاص بك",
  },
  CVVCode: {
    en: "CVV Code",
    ar: "رمز CVV",
  },
  expiryDate: {
    en: "Expiry date",
    ar: "تاريخ الأنتهاء",
  },
  firstName: {
    en: "Firstname",
    ar: "الأسم الأول",
  },
  secondName: {
    en: "Second Name",
    ar: "الأسم الثاني",
  },
  lastName: {
    en: "Lastname",
    ar: "الأسم الأخير",
  },
  mobileNumber: {
    en: "Mobile Number",
    ar: "رقم الجوال",
  },
  country: {
    en: "Country",
    ar: "الدولة",
  },
  city: {
    en: "City",
    ar: "المدينة",
  },
  personal: {
    en: "Personal",
    ar: "شخصي",
  },
  representInstitution: {
    en: "Facility representative",
    ar: "ممثل منشأة",
  },
  healthWork: {
    en: "Health Professional",
    ar: "عمل صحي",
  },
  managementWork: {
    en: "Administrative work",
    ar: "عمل اداري",
  },
  reasonsForTraining: {
    en: "Reasons for training",
    ar: "أسباب التدريب",
  },
  personalGoalsOfTraining: {
    en: "Personal goals to be achieved from the training",
    ar: "الأهداف الشخصية المراد تحقيقها من التدريب",
  },
  administrativeGoalsOfTraining: {
    en: "Objectives of the institution to be achieved from the training",
    ar: "اهداف المؤسسة  المراد تحقيقها من التدريب",
  },
  firstGoal: {
    en: "First goal",
    ar: "الهدف الأول",
  },
  secondGoal: {
    en: "Second goal",
    ar: "الهدف الثاني",
  },
  thirdGoal: {
    en: "Third goal",
    ar: "الهدف الثالث",
  },
  highPriorityJobTasks: {
    en: "High priority job tasks",
    ar: "مهام وظيفية ذات اولوية عالية",
  },
  mediumPriorityJobTasks: {
    en: "Medium priority job tasks",
    ar: "مهام وظيفية ذات اولوية متوسطة",
  },
  lowPriorityJobTasks: {
    en: "Low priority job tasks",
    ar: "مهام وظيفية ذات اولوية منخفضة",
  },
  firstTask: {
    en: "First task",
    ar: "المهمة الأولي",
  },
  secondTask: {
    en: "Second task",
    ar: "المهمة الثانية",
  },
  thirdTask: {
    en: "Third task",
    ar: "المهمة الثالثة",
  },
  skillsOfTraining: {
    en: "What are the skills to be gained from the training?",
    ar: "ما هي المهارات المراد تحقيقها من التدريب؟",
  },
  firstSkill: {
    en: "First skill",
    ar: "المهارة الأولي",
  },
  secondSkill: {
    en: "Second skill",
    ar: "المهارة الثانية",
  },
  thirdSkill: {
    en: "Third skill",
    ar: "المهارة الثالثة",
  },
  programsAndCoursesYouWouldLikeToAttend: {
    en: "Please select the programs and courses you would like to attend",
    ar: "الرجاء تحديد البرامج و الدورات التي ترغب بحضورها",
  },
  program: {
    en: "Program...",
    ar: "برنامج...",
  },
  otherInformationToShare: {
    en: "Other information to share",
    ar: "معلومات اخري تشاركها",
  },
  healthPractitioner: {
    en: "Health practitioner?",
    ar: "ممارس صحي؟",
  },
  healthyPractitioner: {
    en: "Health practitioner?",
    ar: "ممارس صحي؟",
  },
  chooseSpecialty: {
    en: "Choose a specialty",
    ar: "اختار التخصص",
  },
  specialty: {
    en: "Specialty",
    ar: "التخصص",
  },
  classificationNumber: {
    en: "Classification number",
    ar: "رقم التصنيف",
  },
  register: {
    en: "Register",
    ar: "التسجيل",
  },
  yes: {
    en: "Yes",
    ar: "نعم",
  },
  no: {
    en: "No",
    ar: "لا",
  },
  myProfile: {
    en: "My Profile",
    ar: "حسابي",
  },
  profile: {
    en: "My Profile",
    ar: "حسابي",
  },
  myPersonalData: {
    en: "My personal data",
    ar: "بياناتي الشخصية",
  },
  myCourses: {
    en: "My courses",
    ar: "دوراتي",
  },
  invoiceNumber: {
    en: "Invoice number",
    ar: "رقم الفاتورة",
  },
  certificate: {
    en: "Certificate",
    ar: "الشهادة",
  },
  sentSuccessfully: {
    en: "Sent successfully",
    ar: "تم إرسال البيانات بنجاح",
  },
  ok: {
    en: "Ok",
    ar: "حسناً",
  },
  resetCode: {
    en: "Reset code",
    ar: "كود الاستعادة",
  },
  enterResetCode: {
    en: "Enter reset code",
    ar: "أدخل كود الاستعادة",
  },
  editProfile: {
    en: "Edit my personal data",
    ar: "تعديل بياناتي الشخصية",
  },
  enterPhoneNumber: {
    en: "Enter Phone Number",
    ar: "أدخل رقم الجوال",
  },
  image: {
    en: "Image",
    ar: "الصورة",
  },
  logout: {
    en: "Logout",
    ar: "تسجيل الخروج",
  },
  bankTransfer: {
    en: "Bank Transfer",
    ar: "تحويل بنكي",
  },
  mada: {
    en: "Mada",
    ar: "مدي",
  },
  searchForCourses: {
    en: "Search for courses",
    ar: "البحث عن الدورات",
  },
  bankTransferTransfer: {
    en: "Bank transfer transfer",
    ar: "تحويل حوالة بنكية",
  },
  bankTransferFrom: {
    en: "Bank transferred from",
    ar: "البنك المحول منه",
  },
  accountOwnerName: {
    en: "Account Holder's Name",
    ar: "اسم صاحب الحساب",
  },
  attachReceipt: {
    en: "Attach receipt",
    ar: "إرفاق الإيصال",
  },
  confirmTransfer: {
    en: "Confirm transfer",
    ar: "تأكيد التحويل",
  },
  cancelOperation: {
    en: "Cancel operation",
    ar: "إلغاء العملية",
  },
  bankTransferMessage: {
    en: "The order will be confirmed after the transfer is confirmed by the administration",
    ar: "سيتم تأكيد الطلب بعد تأكيد التحويل من قبل الإدارة",
  },
  searchResults: {
    en: "Search results",
    ar: "نتائج البحث",
  },
  noSearchResults: {
    en: "No courses was found",
    ar: "لم يتم العثور علي أي دروات مشابهة",
  },
  goToHome: {
    en: "Go to home",
    ar: "الرجوع للرئسية",
  },
  visitLink: {
    en: "Visit link",
    ar: "زيارة الرابط",
  },
  cart: {
    en: "Subscribe",
    ar: "الاشتراك في الدورة",
  },
  cartIsEmpty: {
    en: "Cart is empty",
    ar: "سلة المشتريات لا تحتوي اي عناصر",
  },
  verifyCode: {
    en: "Verify code",
    ar: "تأكيد الكود",
  },
  deleteCartItemConfirm: {
    en: "Do you confirm that the item is removed from the cart?",
    ar: "هل تؤكد حذف العنصر من سلة المشتريات؟",
  },
  remove: {
    en: "Remove",
    ar: "حذف",
  },
  hint: {
    en: "Hint",
    ar: "تلميح",
  },
  finishTheExam: {
    en: "Finish the exam",
    ar: "إنهاء الإختبار",
  },
  restartTheExam: {
    en: "Exam repetiton",
    ar: "إعادة الإختبار",
  },
  status: {
    en: "Status",
    ar: "الحالة",
  },
  question: {
    en: "Question",
    ar: "السؤال",
  },
  yourDegreeIs: {
    en: "Your degree is : ",
    ar: "درجتك هي : ",
  },
  wellDoneYouPassedThisExam: {
    en: "Well done, you passed the exam",
    ar: "احسنت لقد نجحت في الإمتحان",
  },
  tryAgainHarderNextTime: {
    en: "Try hard next time",
    ar: "حاول اكثر في المرة القادمة",
  },
  yourAnswerIsCorrect: {
    en: "Your answer is correct",
    ar: "إجابتك صحيحة",
  },
  yourAnswerIsNotCorrect: {
    en: "Your answer is not correct",
    ar: "إجابتك غير صحيحة",
  },
  showRightAnswer: {
    en: "To find out the correct answer, click here",
    ar: "لمعرفة الإجابة الصحيحة إضغط هنا",
  },
  rightAnswer: {
    en: "Right answer",
    ar: "الإجابة الصحيحة",
  },
  paymentMethods: {
    en: "Payment methods",
    ar: "وسائل الدفع",
  },
  goBack: {
    en: "Go Back",
    ar: "الصفحة السابقة",
  },
  noCoursesWasFound: {
    en: "No courses was found",
    ar: "لم يتم العثور علي دورات",
  },
  totalPrice: {
    en: "Toal price",
    ar: "السعر الإجمالي",
  },
  cartItems: {
    en: "Cart items",
    ar: "عناصر السلة",
  },
  gender: {
    en: "Gender",
    ar: "الجنس",
  },
  male: {
    en: "Male",
    ar: "ذكر",
  },
  female: {
    en: "Female",
    ar: "أنثي",
  },
  showSidebarMenu: {
    en: "Show sidebar menu",
    ar: "إظهار القائمة الجانبية",
  },
  idNumber: {
    en: "ID Number",
    ar: "رقم الهوية",
  },
  enterIdNumber: {
    en: "Enter ID Number",
    ar: "أدخل رقم الهوية",
  },
  specialityNumber: {
    en: "Health Specialties Authority No",
    ar: "رقم هيئة التخصصات الصحية",
  },
  cover: {
    en: "Cover",
    ar: "الغلاف",
  },
  type: {
    en: "Type",
    ar: "النوع",
  },
  birthDate: {
    en: "Birth date",
    ar: "تاريخ الميلاد",
  },
  inCart: {
    en: "Continue  purchase",
    ar: "متابعة الشراء",
  },
  noPaymentsActivated: {
    en: "There is no active payment methods",
    ar: "لا يوجد وسائل دفع مفعلة",
  },
  watchContent: {
    en: "Watch content",
    ar: "مشاهدة المحتوي",
  },
  chooseAValue: {
    en: "Choose a value",
    ar: "اختر قيمة",
  },
  paymentSuccess: {
    en: "The course has been bougth successfully.",
    ar: "تم شراء الدورة بنجاح.",
  },
  checkoutSuccess: {
    en: "The course has been bougth successfully.",
    ar: "تم شراء الدورة بنجاح.",
  },
  checkoutFailed: {
    en: "Sorry, Faild to buy the course.",
    ar: "تعذر شراء الدورة.",
  },
  thisCourseHasNoContents: {
    en: "Please note that this course does not contain any content yet.",
    ar: "برجاء العلم ان هذه الدورة لا تحتوي علي اية محتويات بعد.",
  },
  to: {
    en: "to",
    ar: "الي",
  },
  goToLocationUrl: {
    en: "Go to location url",
    ar: "الذهاب الي رابط الموقع",
  },
  youAreOffline: {
    en: "You are offline now!",
    ar: "لقد فقدت الإتصال بالإنترنت!",
  },
  upcoming: {
    en: "Upcoming",
    ar: "قادمة",
  },
  expired: {
    en: "Ended",
    ar: "منتهية",
  },
  print: {
    en: "Print",
    ar: "طباعة الفاتورة",
  },
  download: {
    en: "Download",
    ar: "تحميل",
  },
  percentageTax: {
    en: "Tax rate",
    ar: "نسبة الضريبة",
  },
  myOrders: {
    en: "My orders",
    ar: "طلباتي",
  },
  price: {
    en: "Price",
    ar: "السعر",
  },
  paymentMethod: {
    en: "Payment Method",
    ar: "وسيلة الدفع",
  },
  date: {
    en: "Date",
    ar: "التاريخ",
  },
  view: {
    en: "View",
    ar: "عرض",
  },
  orderDetails: {
    en: "Order Details",
    ar: "تفاصيل الطلب",
  },
  orderId: {
    en: "Order Id",
    ar: "رقم الطلب",
  },
  createdAt: {
    en: "Created",
    ar: "رقم الطلب",
  },
  orderItems: {
    en: "Order Items",
    ar: "عناصر الطلب",
  },
  noBody: {
    en: "noBody",
    ar: "لا أحد",
  },
  fromTime: {
    en: "From",
    ar: "من الساعة",
  },
  toTime: {
    en: "to",
    ar: "إلي الساعة",
  },
  am: {
    en: "am",
    ar: "ص",
  },
  pm: {
    en: "pm",
    ar: "م",
  },
  minutes: {
    en: "Minutes",
    ar: "دقيقة",
  },
  minute: {
    en: "Minute",
    ar: "دقيقة",
  },
  downloadCertificate: {
    en: "Download Certificate",
    ar: "تحميل الشهادة",
  },
  from: {
    en: "from",
    ar: "من",
  },
  noQuestions: {
    en: "No questions added, yet",
    ar: "لم تتم إضافة اسئلة بعد",
  },
  youAreNotAllowedToUseTheSystem: {
    en: "You are not allowed to use the system. Please contact the system adminstrator.",
    ar: "عذرا انت غير مصرح بإستخدام النظام, من فضلك حاول التواصل مع الإدارة",
  },
  visa: {
    en: "visa",
    ar: "فيزا",
  },
  contactData: {
    en: "Contact information",
    ar: "بيانات التواصل",
  },
  duration: {
    en: "Duration",
    ar: "المدة",
  },
  webUrl: {
    en: "location",
    ar: "رابط الموقع",
  },
  inWaitingedara: {
    en: "Waiting for the administration's response to the subscription request",
    ar: "فى انتظار رد الإداره على طلب الإشتراك",
  },
});
