import styled from "@emotion/styled";
// import PhoneInput from "react-phone-number-input";
import PhoneInput from "react-phone-input-2";

import { theme } from "../../..";

export const CustomPhoneInput = styled(PhoneInput)({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  backgroundColor: theme.colors.gray.inputs,
  color: theme.colors.gray[400],
  borderRadius: "10px",

  "& .form-control": {
    width: "100%",
    backgroundColor: `${theme.colors.gray.inputs} !important`,
    flexGrow: "1",
    border: "0 !important",
    outline: "0 !important",
    minHeight: "60px",
    fontSize: "24px",
    lineHeight: "29.7px",
    padding: "0px 25px",
    maxWidth: "100%",
    direction: "ltr",
    order: 2,
    marginLeft: "15%",

    "@media screen and (max-width: 550px)": {
      marginLeft: "20%",
    },
    "& ::placeholder": {
      color: theme.colors.gray[400],
    },
  },

  "& .flag-dropdown": {
    order: 1,
    minWidth: "15%",
    right: "unset",
    left: "0px",
    border: "0px !important",

    "@media screen and (max-width: 550px)": {
      minWidth: "20%",
    },

    "& .selected-flag": {
      width: "100%",
      "&:focus, &:hover": {
        backgroundColor: `${theme.colors.gray.inputs} !important`,
      },
      "& .flag": {
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%) scale(1.8) !important",
        marginTop: "unset",

        "& .arrow": {
          left: "20px",
        },
      },
    },
  },
});
