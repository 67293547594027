import styled from "@emotion/styled";
import { theme } from "../..";
import { ParagraphProps } from "../types";

export const MainTitle = styled("h1")<ParagraphProps>(
  {
    fontFamily: "GraphikArabic",
    overflowWrap: "anywhere",
  },
  ({ color, align, fontSize, m0, fontWeight }) => ({
    color: color ? theme.colors.primary[color] : theme.colors.primary.teal,
    textAlign: align || "start",
    fontSize: fontSize || "3rem",
    margin: m0 ? "0px" : "",
    fontWeight: fontWeight || "bold",
  }),
);

export const SectionTitle = styled("h2")<ParagraphProps>(
  {
    fontFamily: "GraphikArabic",
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: "normal",
    lineHeight: "normal",
    overflowWrap: "anywhere",
  },
  ({ color, align, fontSize, m0, fontWeight }) => ({
    color: color ? theme.colors.primary[color] : theme.colors.primary.teal,
    textAlign: align || "start",
    fontSize: fontSize || "2.8rem",
    margin: m0 ? "0px" : "",
    fontWeight: fontWeight || "bold",

    "@media screen and (max-width: 600px)": {
      fontSize: fontSize || "2.4rem",
    },

    "@media screen and (max-width: 500px)": {
      fontSize: fontSize || "2rem",
    },

    "@media screen and (max-width: 450px)": {
      fontSize: fontSize || "1.8rem",
    },
  }),
);

export const SectionSecondaryTitle = styled("h3")<ParagraphProps>(
  {
    fontFamily: "GraphikArabic",
    fontStyle: "normal",
    overflowWrap: "anywhere",
  },
  ({ color, align, fontSize, m0, fontWeight }) => ({
    color: color ? theme.colors.primary[color] : theme.colors.primary.teal,
    textAlign: align || "start",
    fontSize: fontSize || "1.5rem",
    fontWeight: fontWeight || "bold",
    margin: m0 ? "0px" : "",
  }),
);
