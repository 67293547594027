import styled from "@emotion/styled";

export const XSIcon = styled("img")({
  width: "25px",
  height: "25px",
  objectFit: "contain",
});

export const SMIcon = styled("img")({
  width: "35px",
  height: "35px",
  borderRadius: "10px",
});

export const LGIcon = styled("img")({
  width: "45px",
  height: "45px",
  objectFit: "contain",
});

export const MainIcon = styled("img")<{
  size?: string;
}>(
  {
    objectFit: "contain",
  },
  ({ size }) => ({
    width: size || "35px",
    height: size || "35px",
  }),
);
