import styled from "@emotion/styled";
import { Link, LinkProps } from "@mongez/react-router";
import { theme } from "../..";
import SubmitButton from "../Form/SubmitButton";
import { CustomButtonProps } from "../types";

export const MainButton = styled("button")<CustomButtonProps>(
  {
    minWidth: "132px",
    fontFamily: "GraphikArabic",
    borderRadius: "10px",
    outline: "0",
    transition: "all 0.3s ease-in-out",
    textDecoration: "none",
    textAlign: "center",
    height: "fit-content",
    "&:active": {
      transform: "scale(0.98)",
    },
  },
  ({
    color,
    outline,
    fullWidth,
    flexGrow,
    fontSize,
    fontWeight,
    padding,
  }: CustomButtonProps) => ({
    fontSize: fontSize || "18px",
    fontWeight: fontWeight || 450,
    padding: padding || "10px 20px",
    backgroundColor: outline
      ? theme.colors.white
      : color
      ? theme.colors.primary[color]
      : theme.colors.primary.darkBlue,
    color: outline
      ? color
        ? theme.colors.primary[color]
        : theme.colors.primary.darkBlue
      : color === "white"
      ? theme.colors.primary.teal
      : theme.colors.white,
    border: `2px solid ${
      outline
        ? color
          ? theme.colors.primary[color]
          : theme.colors.primary.darkBlue
        : "transparent"
    }`,
    "&:hover, &:active": {
      opacity: !outline ? "0.7" : "1",
      color: color === "white" ? theme.colors.primary.teal : theme.colors.white,
      backgroundColor: color
        ? theme.colors.primary[color]
        : theme.colors.primary.darkBlue,
    },
    width: fullWidth ? "100%" : "fit-content",
    flexGrow: flexGrow ? flexGrow : fullWidth ? "1" : "initial",
    "&:disabled": {
      opacity: "0.5",
    },
  }),
);

export const MainLinkButton = styled(Link)<LinkProps & CustomButtonProps>(
  {
    minWidth: "100px",
    fontFamily: "GraphikArabic",
    borderRadius: "10px",
    outline: "0",
    transition: "all 0.3s ease-in-out",
    textDecoration: "none",
    textAlign: "center",
    height: "fit-content",
    "&:active": {
      transform: "scale(0.98)",
    },
  },
  ({
    color,
    outline,
    fullWidth,
    flexGrow,
    fontSize,
    fontWeight,
    padding,
  }: CustomButtonProps) => ({
    fontSize: fontSize || "18px",
    fontWeight: fontWeight || 430,
    padding: padding || "8px 20px",
    backgroundColor: outline
      ? theme.colors.white
      : color
      ? theme.colors.primary[color]
      : theme.colors.primary.darkBlue,
    color: outline
      ? color
        ? theme.colors.primary[color]
        : theme.colors.primary.darkBlue
      : color === "white"
      ? theme.colors.primary.teal
      : theme.colors.white,
    border: `2px solid ${
      outline
        ? color
          ? theme.colors.primary[color]
          : theme.colors.primary.darkBlue
        : "transparent"
    }`,
    "&:hover, &:active": {
      opacity: !outline ? "0.7" : "1",
      color: color === "white" ? theme.colors.primary.teal : theme.colors.white,
      backgroundColor: color
        ? theme.colors.primary[color]
        : theme.colors.primary.darkBlue,
    },
    width: fullWidth ? "100%" : "fit-content",
    flexGrow: flexGrow ? flexGrow : fullWidth ? "1" : "initial",
    "&:disabled": {
      opacity: "0.5",
    },
  }),
);

export const CustomStyledLink = styled("a")<CustomButtonProps>(
  {
    padding: "8px 20px",
    minWidth: "100px",
    fontFamily: "GraphikArabic",
    fontSize: "18px",
    fontWeight: 430,
    borderRadius: "10px",
    outline: "0",
    transition: "all 0.3s ease-in-out",
    textDecoration: "none",
    textAlign: "center",
    height: "fit-content",
    "&:active": {
      transform: "scale(0.98)",
    },
  },
  ({ color, outline, fullWidth, flexGrow }: CustomButtonProps) => ({
    backgroundColor: outline
      ? theme.colors.white
      : color
      ? theme.colors.primary[color]
      : theme.colors.primary.darkBlue,
    color: outline
      ? color
        ? theme.colors.primary[color]
        : theme.colors.primary.darkBlue
      : color === "white"
      ? theme.colors.primary.teal
      : theme.colors.white,
    border: `2px solid ${
      outline
        ? color
          ? theme.colors.primary[color]
          : theme.colors.primary.darkBlue
        : "transparent"
    }`,
    "&:hover, &:active": {
      opacity: !outline ? "0.7" : "1",
      color: color === "white" ? theme.colors.primary.teal : theme.colors.white,
      backgroundColor: color
        ? theme.colors.primary[color]
        : theme.colors.primary.darkBlue,
    },
    width: fullWidth ? "100%" : "fit-content",
    flexGrow: flexGrow ? flexGrow : fullWidth ? "1" : "initial",
    "&:disabled": {
      opacity: "0.5",
    },
  }),
);

export const CustomSubmitButton = styled(SubmitButton)<CustomButtonProps>(
  {
    padding: "10px 25px",
    minWidth: "132px",
    fontFamily: "GraphikArabic",
    fontSize: "18px",
    fontWeight: 500,
    borderRadius: "10px",
    outline: "0",
    transition: "all 0.3s ease-in-out",
    textDecoration: "none",
    textAlign: "center",
    cursor: "pointer",
    height: "fit-content",
    "&:active": {
      transform: "scale(0.98)",
    },
  },
  ({ color, outline, fullWidth, flexGrow }) => ({
    backgroundColor: outline
      ? theme.colors.white
      : color
      ? theme.colors.primary[color]
      : theme.colors.primary.darkBlue,
    color: outline
      ? color
        ? theme.colors.primary[color]
        : theme.colors.primary.darkBlue
      : color === "white"
      ? theme.colors.primary.teal
      : theme.colors.white,
    border: `2px solid ${
      outline
        ? color
          ? theme.colors.primary[color]
          : theme.colors.primary.darkBlue
        : "transparent"
    }`,
    "&:hover, &:active": {
      opacity: !outline ? "0.7" : "1",
      color: color === "white" ? theme.colors.primary.teal : theme.colors.white,
      backgroundColor: color
        ? theme.colors.primary[color]
        : theme.colors.primary.darkBlue,
    },
    width: fullWidth ? "100%" : "fit-content",
    flexGrow: flexGrow ? flexGrow : fullWidth ? "1" : "initial",
    "&:disabled": {
      opacity: "0.5",
    },
  }),
);

export const CustomLink = styled(Link)({
  textDecoration: "none",
  display: "flex",
  alignItems: "center",
  gap: "8px",
});

export const ExternalLink = styled("a")({
  textDecoration: "none",
  display: "flex",
  alignItems: "center",
  gap: "8px",
});

export const Div = styled("div")({});
