import { MainImageIMG } from "./styles";

export interface IMainImageProps {
  src?: string;
  alt?: string;
}

export const MainImage = ({
  src,
  alt = "Descriptive Image",
}: IMainImageProps) => <MainImageIMG src={src} alt={alt} />;
