import { trans } from "@mongez/localization";
import { FormInputProps, useFormInput } from "@mongez/react-form";
import { useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import { Flex, LightGraySpan } from "../styles";
import HiddenInput from "./HiddenInput";
import InputLabel from "./InputLabel";

export type DatePickerInputProps = FormInputProps;

export default function DatePickerInput(props: DatePickerInputProps) {
  const { name, label, value, onChange, placeholder, required, otherProps } =
    useFormInput(props);

  const formatDate = (dateValue: any): string => {
    if (dateValue?.format) {
      const [dateDetails, ,] = dateValue.format.split(" ");
      const [day, month, year] = dateDetails.split("-");
      return `${year}-${month}-${day}`;
    } else {
      const date = new Date(dateValue);

      const year = date.getFullYear();
      const month = (1 + date.getMonth()).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    }
  };

  const defaultValue = useMemo(
    () =>
      props?.defaultValue
        ? formatDate(
            props?.defaultValue?.format
              ? props?.defaultValue
              : { format: props?.defaultValue },
          )
        : "",
    [props?.defaultValue],
  );

  const currentValue = formatDate(value) || defaultValue;

  const [selected, setSelected] = useState(
    defaultValue ? new Date(formatDate(defaultValue)) : new Date(),
  );

  return (
    <Flex
      flexDirection="column"
      gap="5px"
      className="form-control"
      fullWidth={props.fullWidth !== undefined ? props?.fullWidth : true}
      style={{ ...props?.containerStyles }}>
      <HiddenInput
        name={name}
        value={
          currentValue === "NaN-NaN-NaN" ? formatDate(new Date()) : defaultValue
        }
      />
      {label && (
        <InputLabel required={required}>
          <LightGraySpan>{trans(label as string)}</LightGraySpan>
        </InputLabel>
      )}

      <div>
        <DatePicker
          selected={selected}
          onChange={(date: Date) => {
            setSelected(date);
            onChange({
              target: {
                value: date,
              },
            });
          }}
          defaultValue={defaultValue}
          placeholder={placeholder && trans(placeholder as string)}
          {...otherProps}
        />
      </div>
    </Flex>
  );
}

DatePickerInput.defaultProps = {
  //
};
