import createCache from "@emotion/cache";
import { MantineProvider } from "@mantine/core";
import { setFavIcon } from "@mongez/dom";
import { current } from "@mongez/react";
import { pipeline, preload } from "@mongez/react-utils";
import { getGuestToken, getSettings } from "app/global/services/GlobalServices";
import { Suspense } from "react";
import { ToastContainer } from "react-toastify";
import rtlPlugin from "stylis-plugin-rtl";
import { theme } from "../..";
import { settingsAtom } from "../../../atoms/settings-atom";
import { MainContainer } from "../../components/Containers";
import { Flex } from "../../components/Grid";
import Loader from "../../components/Indicators/Loader";
import LoaderOverlay from "../../components/Loader/Loader";

const cacheRTL = createCache({
  key: "rtl",
  stylisPlugins: [rtlPlugin],
});

function _Root({ children }: any) {
  setFavIcon(settingsAtom.get("general.favicon.url"));
  return (
    <MainContainer>
      <Suspense
        fallback={
          <Flex
            justifyContent="center"
            alignItems="center"
            fullWidth
            style={{
              height: "100vh",
              backgroundColor: theme.colors.light.teal,
            }}>
            <Loader />
            <LoaderOverlay />
          </Flex>
        }>
        <MantineProvider
          theme={{
            fontFamily:
              current("localeCode") === "ar"
                ? "GraphikArabic"
                : "GraphikArabic",
            dir: current("localeCode") === "ar" ? "rtl" : "ltr",
          }}
          emotionCache={current("localeCode") === "ar" ? cacheRTL : undefined}
          withGlobalStyles
          withNormalizeCSS>
          <ToastContainer
            position={current("localeCode") === "ar" ? "top-left" : "top-right"}
            autoClose={4000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={current("localeCode") === "ar" ? true : false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          {children}
        </MantineProvider>
      </Suspense>
    </MainContainer>
  );
}

const Root = preload(_Root, pipeline([getGuestToken, getSettings]));

export default Root;
