import Arabic from "./arabic-icon-translations.svg";
import ArrowLeftWhite from "./arrow-circle-left-white.svg";
import ArrowLeft from "./arrow-circle-left.svg";
import ArrowRightWhite from "./arrow-circle-right-white.svg";
import ArrowRight from "./arrow-circle-right.svg";
import ArrowDown from "./arrow-down.svg";
import arrowDownGray from "./arrowDownGray.svg";
import { default as User, default as UserIcon } from "./avatar/avatrr.svg";
import GroupIcon from "./avatar/group-icon.svg";
import TagUser from "./avatar/tag-user.svg";
import UserAdd from "./avatar/user-cirlce-add.svg";
import UserSquare from "./avatar/user-octagon.svg";
import BankIcon from "./bank.svg";
import BankActiveIcon from "./bankActive.svg";
import CheckMarkOrange from "./check-mark-square _orange.svg";
import CheckMarkOrangeOutline from "./check-mark-square _orangeoutline.svg";
import CheckMark from "./check-mark-square.svg";
import ClockSquare from "./clockSquare.svg";
import CloseIconRed from "./close-btn-red.svg";
import CloseIcon from "./close-btn.svg";
import CalenderIcon from "./date-calender.svg";
import EditBtn from "./editBtn.svg";
import English from "./english-icon-translations.svg";
import HeartIcon from "./heart.svg";
import HeartFillIcon from "./heartFill.svg";
import HeartWhiteIcon from "./heartWhite.svg";
import HeartWhiteFillIcon from "./heartWhiteFill.svg";
import Home from "./home.svg";
import IIconCircle from "./i-icon.svg";
import IIcon from "./Icon awesome-info-circle.svg";
import LocationIcon from "./locationIcon.svg";
import LogoColorful from "./logo/colorful/logo.webp";
import LogoColorful2X from "./logo/colorful/logo@2x.webp";
import LogoColorful3X from "./logo/colorful/logo@3x.webp";
import LogoColorless from "./logo/colorless/logo.webp";
import LogoColorless2X from "./logo/colorless/logo@2x.webp";
import LogoColorless3X from "./logo/colorless/logo@3x.webp";
import MadaColorfulIcon from "./Mada-colorful.svg";
import MadaIcon from "./Mada_Logo.svg";
import PhoneIcon from "./phoneIcon.svg";
import QuestionIcon from "./question-icon.svg";
import Search from "./Search.svg";
import ShoppingCard from "./shoppingCard.svg";
import VideoIconWhite from "./video-icon-white.svg";
import VideoIcon from "./video-icon.svg";
import VideoCamIcon from "./videoCam.svg";
import VisaIcon from "./visa.svg";
import VisaColorfulIcon from "./visaColorful.svg";
import MailIcon from "./vuesax_linear_sms.svg";
import MailIconBold from "./vuesax_linear_sms_blod.svg";

export const Icons = {
  ArrowRight,
  ArrowLeft,
  ArrowRightWhite,
  ArrowLeftWhite,
  ArrowDown,
  EditBtn,
  CalenderIcon,
  VideoIcon,
  VideoIconWhite,
  VideoCamIcon,
  MailIcon,
  MailIconBold,
  CloseIcon,
  CloseIconRed,
  GroupIcon,
  ClockSquare,
  UserIcon,
  ShoppingCard,
  MadaColorfulIcon,
  VisaIcon,
  VisaColorfulIcon,
  Heart: {
    main: HeartIcon,
    fill: HeartFillIcon,
    white: {
      main: HeartWhiteIcon,
      fill: HeartWhiteFillIcon,
    },
  },
  CheckMark,
  LocationIcon,
  PhoneIcon,
  arrowDownGray,
  QuestionIcon,
  IIconCircle,
  IIcon,
  BankIcon,
  BankActiveIcon,
  MadaIcon,
  Home,
  Search,
  language: {
    English,
    Arabic,
  },
  CheckMarkOrange,
  CheckMarkOrangeOutline,
  avatar: {
    TagUser,
    UserAdd,
    UserSquare,
    User,
  },
  logoColorful: {
    main: LogoColorful,
    x2: LogoColorful2X,
    x3: LogoColorful3X,
  },
  logoColorless: {
    main: LogoColorless,
    x2: LogoColorless2X,
    x3: LogoColorless3X,
  },
};
