const URLS = {
  home: "/",
  contactUs: "/contact-us",
  search: {
    route: "/search-results/:search",
    view: (search: string) => `/search-results/${search}`,
  },

  aboutUs: "/about-us",
  ourMessage: "/our-message",
  ourVision: "/our-vision",
  ourMorals: "/our-morals",
  privacyPolicy: "/privacy-policy",
  organizationalChart: "/organizational-chart",

  services: {
    main: "/services",

    viewServiceRoute: "/services/:id",
    viewService: (item: any) => `/services/${item?.id}`,
  },
  courses: {
    main: "/courses",
    favorite: "/favorite-courses",

    viewCourseRoute: "/courses/:id",
    viewCourse: (course: any) => `/courses/${course?.id}`,

    viewCourseLessonRoute: "/courses/:idCourse/:idLesson",
    viewCourseLesson: (lesson: any, course: any) =>
      `/courses/${course?.id}/${lesson?.id}`,

    applyInCourseRoute: "/courses/apply/:idCourse",
    applyInCourse: (course: any) => `/courses/apply/${course?.id}`,
  },
  account: {
    auth: {
      login: "/login",
      signup: "/signup",
      forgetPassword: "/forget-password",
      verifyCode: "/verify-code",
      resetPassword: "/reset-password",
      changePassword: "/my-account/change-password",
    },
    profile: "/my-account",
    editProfile: "/edit-profile",

    viewInvoice: (order: any) => `/my-account/invoice/${order.id}`,
    myCourses: "/my-account/my-courses",
    invoice: "/my-account/invoice/:id",
    myOrders: {
      list: "/my-account/orders",
      singleRoute: "/my-account/orders/:id",
      view: (order: any) => `/my-account/orders/${order.id}`,
    },

    certificates: {
      route: "/my-account/certificates/:courseId",
      view: (course: any) => `/my-account/certificates/${course?.id}`,
    },
  },
  faq: "/faq",
  trainingNeeds: "/training-needs",
  training: "/training",
  pay: {
    checkout: "/checkout",
    checkoutFailed: "/checkout/failed",
    checkoutSuccess: "/checkout/success/:orderId",
    bankAccounts: "/bank-accounts",
    bankTransfer: "/bank-transfer",
    ePay: "/e-pay",
    cart: "/cart",
    paymentSuccess: "/payment-success",
  },
  notFound: "/404",
};

export default URLS;
