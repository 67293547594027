import { MainSpan } from "../../styles";
import { ErrorHandlerProps } from "./types";

export default function ErrorHandler({
  error,
  center = true,
}: ErrorHandlerProps & { center?: boolean }) {
  return (
    <MainSpan color="red" align={center ? "center" : "start"}>
      {error?.message ||
        error?.response?.data?.data?.errors?.[0]?.value ||
        error?.response?.message}
    </MainSpan>
  );
}
