import styled from "@emotion/styled";
import { theme } from "../../../index";
import { CustomInputProps, WhiteRowPropsType } from "../../types";
import DatePickerInput from "../DatePickerInput";
import EmailInput from "../EmailInput";
import NumberInput from "../NumberInput";
import PasswordInput from "../PasswordInput";
import TextAreaInput from "../TextAreaInput";
import TextInput from "../TextInput";

export const inputEssentialStyles: any = {
  backgroundColor: theme.colors.gray.inputs,
  color: theme.colors.gray[400],
  border: 0,
  outline: 0,
  minHeight: "60px",
  fontSize: "24px",
  lineHeight: "29.7px",
  padding: "10px 25px",
  borderRadius: "10px",
  maxWidth: "100%",
  "&::placeholder": {
    color: theme.colors.gray[200],
    fontFamily: "GraphikArabic",
    fontWeight: "400",
    fontSize: "20px",
  },
};

export const tealInputEssentialStyles: any = {
  ...inputEssentialStyles,
  minHeight: "40px",
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  gap: "10px",
  padding: "20px 50px",
  backgroundColor: theme.colors.light.teal,
  cursor: "pointer",
};

export const CustomTextInput = styled(TextInput)<CustomInputProps>(
  {
    ...inputEssentialStyles,
  },
  ({ fullWidth }) => ({
    width: fullWidth ? "100%" : "initial",
    flexGrow: fullWidth ? "1" : "initial",
  }),
);

export const CustomNumberInput = styled(NumberInput)<CustomInputProps>(
  {
    ...inputEssentialStyles,
  },
  ({ fullWidth }) => ({
    width: fullWidth ? "100%" : "initial",
    flexGrow: fullWidth ? "1" : "initial",
  }),
);

export const CustomDatePickerInput = styled(DatePickerInput)<CustomInputProps>(
  {
    ...inputEssentialStyles,
  },
  ({ fullWidth }) => ({
    width: fullWidth ? "100%" : "initial",
    flexGrow: fullWidth ? "1" : "initial",
  }),
);

export const CustomEmailInput = styled(EmailInput)<CustomInputProps>(
  {
    ...inputEssentialStyles,
  },
  ({ fullWidth }) => ({
    width: fullWidth ? "100%" : "initial",
    flexGrow: fullWidth ? "1" : "initial",
  }),
);

export const CustomPasswordInput = styled(PasswordInput)<CustomInputProps>(
  {
    ...inputEssentialStyles,
  },
  ({ fullWidth }) => ({
    width: fullWidth ? "100%" : "initial",
    flexGrow: fullWidth ? "1" : "initial",
  }),
);

export const CustomTextAreaInput = styled(TextAreaInput)<CustomInputProps>(
  {
    ...inputEssentialStyles,
    resize: "vertical",
    alignItems: "flex-start",
  },
  ({ fullWidth }) => ({
    width: fullWidth ? "100%" : "initial",
    flexGrow: fullWidth ? "1" : "initial",
  }),
);

export const WhiteRow = styled("div")<WhiteRowPropsType>(
  {
    padding: "0px 29px",
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    minHeight: "80px",
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  ({ fullWidth }) => ({
    flexGrow: fullWidth ? "1" : "initial",
  }),
);

export const DialCode = styled("input")({
  borderRadius: "8px",
  padding: "0 14px",
  height: "55px",
  "$:placeholder": {
    color: theme.colors.gray[200],
  },
});
