import styled from "@emotion/styled";
import { theme } from "../../index";
import { fontWeightType, ParagraphProps } from "../types";

export const MainSpan = styled("div")<
  ParagraphProps & {
    fullWidth?: boolean;
    fontWeight?: fontWeightType;
  }
>(
  {
    fontFamily: "GraphikArabic",
    overflowWrap: "anywhere",
    textShadow: `0px 0px 0.5px ${theme.colors.primary.white}`,
  },
  ({ color, align, fullWidth, fontSize, fontWeight }) => ({
    fontWeight: fontWeight || "450",
    color: color ? theme.colors.primary[color] : theme.colors.gray[300],
    textAlign: align || "start",
    width: fullWidth ? "100%" : "fit-content",
    fontSize: fontSize || "1.3rem",
    // @media mobile
    "@media screen and (max-width: 700px)": {
      fontSize: ".9rem",
    },
  }),
);

export const LightGraySpan = styled("div")<
  ParagraphProps & { fullWidth?: boolean }
>(
  {
    fontFamily: "GraphikArabic",
    fontWeight: "regular",
    color: theme.colors.gray[200],
    overflowWrap: "anywhere",
  },
  ({ align, fullWidth, fontSize }) => ({
    textAlign: align || "start",
    width: fullWidth ? "100%" : "fit-content",
    fontSize: fontSize || "17px",
  }),
);

export const TealSpan = styled("span")({
  fontFamily: "GraphikArabic",
  fontWeight: "bold",
  fontSize: "20px",
  lineHeight: "37px",
  color: theme.colors.primary.teal,
});

export const PrimarySpan = styled("span")({
  ...theme.headings.h12,
  overflowWrap: "anywhere",
  color: theme.colors.primary.darkBlue,
});

export const DangerSpan = styled("span")({
  ...theme.headings.h12,
  overflowWrap: "anywhere",
  color: theme.colors.primary.red,
});
