import cache from "@mongez/cache";
import Endpoint, { setCurrentEndpoint } from "@mongez/http";
import { current } from "@mongez/react";
import { navigateTo } from "@mongez/react-router";
import URLS from "app/utils/urls";
import { AxiosResponse } from "axios";
import user from "user";

export const apiUrl = process.env.REACT_APP_API_URL as string;

const endpoint = new Endpoint({
  putToPost: true,
  baseURL: process.env.REACT_APP_API_URL,
  setAuthorizationHeader: () => {
    if (user.getAccessToken()) {
      return `Bearer ${user.getAccessToken()}`;
    }

    return `key ${process.env.REACT_APP_API_KEY}`;
  },
});

const endpointEvents = endpoint.events;

endpointEvents.beforeSending(config => {
  const headers: any = config.headers;
  headers["os"] = "wui";
  headers["locale-code"] = current("localeCode");
  headers["CURRENCY"] = cache.get("currency");
});

endpointEvents.onSuccess((response: AxiosResponse) => {
  if (response.data.data) {
    response.data = response.data.data;
  }

  if (response.data.student) {
    user.login({
      ...user.all(),
      ...response.data.student,
    });
  }

  if (response.data.authorization) {
    user.login(response.data.authorization);
  }
});

endpointEvents.onError(response => {
  if (response.status === 401) {
    user.logout();
    navigateTo(URLS.account.auth.login);
  }
});

setCurrentEndpoint(endpoint);

export default endpoint;
