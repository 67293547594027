import styled from "@emotion/styled";
import { theme } from "../..";
import { fontWeightType } from "../types";

const badgeStyle: any = {
  borderRadius: "5px",
  fontFamily: "GraphikArabic",
  transition: "all 0.3s ease-in-out",
  minWidth: "fit-content",
  textAlign: "center",
  flexGrow: "0",
  alignSelf: "flex-start",

  "&:hover": {
    opacity: "0.7",
  },
};

type SpanProps = {
  fullWidth?: boolean;
  borderRadius?: string;
  padding?: string;
  fontWeight?: fontWeightType;
  fontSize?: string;
};

export const DangerBadge = styled("div")<SpanProps>(
  {
    ...badgeStyle,
    color: theme.colors.white,
    backgroundColor: theme.colors.primary.red,
  },
  ({ fullWidth, borderRadius, padding, fontSize, fontWeight }) => ({
    width: fullWidth
      ? `calc( 100% - ${padding ? padding + " * 2" : "30px"} )`
      : "fit-content",
    borderRadius: borderRadius || "10px",
    padding: padding || "10px",
    fontSize: fontSize || "15px",
    fontWeight: fontWeight || "400",
  }),
);

export const TealBadge = styled("div")<SpanProps>(
  {
    ...badgeStyle,
    color: theme.colors.white,
    backgroundColor: theme.colors.primary.teal,
  },
  ({ fullWidth, borderRadius, padding, fontSize, fontWeight }) => ({
    width: fullWidth
      ? `calc( 100% - ${padding ? padding + " * 2" : "30px"} )`
      : "fit-content",
    borderRadius: borderRadius || "10px",
    padding: padding || "10px",
    fontSize: fontSize || "15px",
    fontWeight: fontWeight || "400",
  }),
);

export const SuccessBadge = styled("div")<SpanProps>(
  {
    ...badgeStyle,
    color: theme.colors.white,
    backgroundColor: theme.colors.primary.green,
  },
  ({ fullWidth, borderRadius, padding, fontSize, fontWeight }) => ({
    width: fullWidth
      ? `calc( 100% - ${padding ? padding + " * 2" : "30px"} )`
      : "fit-content",
    borderRadius: borderRadius || "10px",
    padding: padding || "10px",
    fontSize: fontSize || "15px",
    fontWeight: fontWeight || "400",
  }),
);

export const PrimaryBadge = styled("div")<SpanProps>(
  {
    ...badgeStyle,
    color: theme.colors.white,
    backgroundColor: theme.colors.primary.darkBlue,
    transition: "all 0.3s ease-in-out",
  },
  ({ fullWidth, borderRadius, padding, fontSize, fontWeight }) => ({
    width: fullWidth
      ? `calc( 100% - ${padding ? padding + " * 2" : "30px"} )`
      : "fit-content",
    borderRadius: borderRadius || "10px",
    padding: padding || "10px",
    fontSize: fontSize || "15px",
    fontWeight: fontWeight || "400",
  }),
);

export const DangerBadgeLight = styled("div")<SpanProps>(
  {
    ...badgeStyle,
    color: theme.colors.primary.red,
    backgroundColor: theme.colors.light.red,
  },
  ({ fullWidth, borderRadius, padding, fontSize, fontWeight }) => ({
    width: fullWidth
      ? `calc( 100% - ${padding ? padding + " * 2" : "30px"} )`
      : "fit-content",
    borderRadius: borderRadius || "10px",
    padding: padding || "10px",
    fontSize: fontSize || "15px",
    fontWeight: fontWeight || "400",
  }),
);

export const TealBadgeLight = styled("div")<SpanProps>(
  {
    ...badgeStyle,
    color: theme.colors.primary.teal,
    backgroundColor: theme.colors.light.teal,
  },
  ({ fullWidth, borderRadius, padding, fontSize, fontWeight }) => ({
    width: fullWidth
      ? `calc( 100% - ${padding ? padding + " * 2" : "30px"} )`
      : "fit-content",
    borderRadius: borderRadius || "10px",
    padding: padding || "10px",
    fontSize: fontSize || "15px",
    fontWeight: fontWeight || "400",
  }),
);

export const SuccessBadgeLight = styled("div")<SpanProps>(
  {
    ...badgeStyle,
    color: theme.colors.primary.green,
    backgroundColor: "#E5F2E3" || theme.colors.light.green,
  },
  ({ fullWidth, borderRadius, padding, fontSize, fontWeight }) => ({
    width: fullWidth
      ? `calc( 100% - ${padding ? padding + " * 2" : "30px"} )`
      : "fit-content",
    borderRadius: borderRadius || "10px",
    padding: padding || "10px",
    fontSize: fontSize || "15px",
    fontWeight: fontWeight || "400",
  }),
);

export const PrimaryBadgeLight = styled("div")<SpanProps>(
  {
    ...badgeStyle,
    color: theme.colors.primary.darkBlue,
    backgroundColor: theme.colors.light.darkBlue,
  },
  ({ fullWidth, borderRadius, padding, fontSize, fontWeight }) => ({
    width: fullWidth ? `100%` : "fit-content",
    borderRadius: borderRadius || "10px",
    padding: padding || "10px",
    fontSize: fontSize || "15px",
    fontWeight: fontWeight || "400",
  }),
);
