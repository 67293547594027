import { getActiveForm } from "@mongez/react-form";
import React, { useCallback, useRef, useState } from "react";
import { BiImageAdd } from "react-icons/bi";
import { ImAttachment } from "react-icons/im";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import { theme } from "../../..";
import { uploadFiles } from "../../../../global/services/GlobalServices";
import { Flex } from "../../Grid";
import { FlexOptions } from "../../types";
import { MainSpan } from "../../Typography";
import { FileInputContainerFluid } from "./styles";

export interface IUploadInputFluidProps {
  onChange: any;
  defaultValue?: any;
  placeholder?: string;
  justifyContent?: FlexOptions;
  required?: boolean;
  attach?: boolean;
  name?: string;
  accept?: string;
}

export const UploadInputFluidComp = ({
  onChange: incomingOnChange,
  defaultValue,
  placeholder,
  justifyContent,
  required,
  name,
  attach,
  accept,
}: IUploadInputFluidProps) => {
  const ref = useRef<HTMLInputElement>(null);
  const [uploaded, setUploaded] = useState<any[]>([]);
  const [hashes, setHashes] = useState(
    defaultValue?.hash
      ? [defaultValue?.hash]
      : defaultValue
      ? [defaultValue]
      : [],
  );

  const onClick = () => ref.current?.click();

  const onChange = useCallback(
    (e: React.ChangeEvent) => {
      const target = e.target as HTMLInputElement;
      const file = target?.files?.[0];
      const formData = new FormData();

      if (file) formData.append("uploads[]", file);

      const form = getActiveForm();

      if (form) {
        form.disable(true);
      }

      uploadFiles(formData)
        .then((response: any) => {
          setUploaded(response?.data?.uploads);

          setHashes(response?.data?.uploads?.map((ele: any) => ele?.hash));

          incomingOnChange({
            target: {
              value: response.data?.uploads?.[0]?.hash,
            },
          });
        })
        .catch(error => {
          toast.error(
            error?.response?.data?.data?.errors[0]?.value || error?.message,
          );
        })
        .finally(() => {
          if (form) {
            form.disable(false);
          }
        });
      target.value = "";
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setUploaded, setHashes],
  );

  const deleteImage = () => {
    setUploaded([]);
    setHashes([]);

    incomingOnChange({
      target: {
        value: "",
      },
    });
  };

  return (
    <>
      <input name={name} type="text" hidden value={hashes?.[0]} />

      <input
        ref={ref}
        type="file"
        multiple={false}
        onChange={onChange}
        hidden
        accept={accept}
      />

      <FileInputContainerFluid>
        <Flex
          justifyContent={justifyContent || "space-between"}
          alignItems="center"
          style={{ height: "100%" }}
          fullWidth>
          {placeholder && <MainSpan onClick={onClick}>{placeholder}</MainSpan>}

          {uploaded?.length ? (
            <MdDelete
              size={25}
              color={theme.colors.primary.red}
              onClick={deleteImage}
              style={{ height: "100%" }}
            />
          ) : attach ? (
            <ImAttachment
              size={25}
              onClick={onClick}
              color={
                required
                  ? theme.colors.primary.red
                  : theme.colors.primary.darkBlue
              }
            />
          ) : (
            <BiImageAdd
              size={40}
              onClick={onClick}
              color={
                required
                  ? theme.colors.primary.red
                  : theme.colors.primary.darkBlue
              }
            />
          )}
        </Flex>
      </FileInputContainerFluid>
    </>
  );
};
