export const breakpoints = {
  xs: "280px",
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1200px",
  xxl: "1360px",
  hd: "1900px",
};

export const onEnglish = "html[dir=ltr] &";
export const onArabic = "html[dir=rtl] &";

export const theme = {
  colors: {
    primary: {
      darkBlue: "#0c60a9",
      lightBlue: "#0977aa",
      teal: "#1baab1",
      greenYellow: "#d5d42d",
      green: "#66b45d",
      red: "#ff4747",
      white: "#ffffff",
      black: "#000000",
    },
    light: {
      darkBlue: "#e9f1f7",
      lightBlue: "#e6fffd",
      teal: "#f1fafb",
      green: "#cedbcd",
      red: "#ffd1d4",
    },
    gray: {
      inputs: "#f3f4f4",
      100: "#dadbdb",
      200: "#999d9d",
      300: "#717171",
      400: "#5d5f60",
    },
    outLines: {
      blue: "#0c60a9",
      teal: "#1baab1",
      yellow: "#d5d42d",
      gray: "#999d9d",
    },
    white: "#ffffff",
  },
  gradients: {
    main: "linear-gradient(52deg, #1baab1 2%, #d5d42d 147%)",
    slider:
      "linear-gradient(90deg, rgba(0, 0, 0, 0.125) 25%, rgba(27,170,177,0.75) 100%)",
    sliderEn:
      "linear-gradient(90deg, rgba(27,170,177,0.75) 0%, rgba(0, 0, 0, 0.125) 75%)",
  },
  boxShadow: {
    light: "0 0 66px 0 rgba(0, 0, 0, 0.07)",
    dark: "0 0 66px 0 rgba(0, 0, 0, 0.22)",
    mid: "0 3px 16px 0 rgba(0, 0, 0, 0.06)",
    pagination: "0 3px 6px 0 rgba(0, 164, 151, 0.31)",
  },
  headings: {
    h1: {
      fontFamily: "GraphikArabic",
      fontWeight: "bold",
      fontSize: "80px",
      lineHeight: "78px",
    },
    h2: {
      fontFamily: "GraphikArabic",
      fontWeight: "bold",
      fontSize: "70px",
      lineHeight: "78px",
    },
    h3: {
      fontFamily: "GraphikArabic",
      fontWeight: "bold",
      fontSize: "51px",
      lineHeight: "75px",
    },
    h4: {
      fontFamily: "GraphikArabic",
      fontWeight: "600",
      fontSize: "38px",
      lineHeight: "52px",
    },
    h5: {
      fontFamily: "GraphikArabic",
      fontWeight: "bold",
      fontSize: "34px",
      lineHeight: "43px",
    },
    h6: {
      fontFamily: "GraphikArabic",
      fontWeight: "400",
      fontSize: "30px",
      lineHeight: "43px",
    },
    h7: {
      fontFamily: "GraphikArabic",
      fontWeight: "400",
      fontSize: "30px",
      lineHeight: "43px",
    },
    h8: {
      fontFamily: "GraphikArabic",
      fontWeight: "regular",
      fontSize: "30px",
      lineHeight: "43px",
    },
    h9: {
      fontFamily: "GraphikArabic",
      fontWeight: "500",
      fontSize: "28px",
      lineHeight: "37px",
    },
    h10: {
      fontFamily: "GraphikArabic",
      fontWeight: "500",
      fontSize: "25px",
      lineHeight: "37px",
    },
    h11: {
      fontFamily: "GraphikArabic",
      fontWeight: "400",
      fontSize: "22px",
      lineHeight: "37px",
    },
    h12: {
      fontFamily: "GraphikArabic",
      fontWeight: "bold",
      fontSize: "20px",
      lineHeight: "37px",
    },
    p: {
      fontFamily: "GraphikArabic",
      fontWeight: "420",
      fontSize: "18px",
      lineHeight: "40px",
    },
  },
  gaps: {
    large: "90px",
    medium: "38px",
    small: "20px",
  },
  dev: {
    img: "https://loremflickr.com/g/320/240/paris",
    video: "https://www.youtube.com/watch?v=5AWRivBk0Gw",
  },
  breakPoints: {
    xs: `@media screen and (max-width: 280px)`,
    sm: `@media screen and (max-width: 576px)`,
    md: `@media screen and (max-width: 768px)`,
    lg: `@media screen and (max-width: 992px)`,
    xl: `@media screen and (max-width: 1200px)`,
    xxl: `@media screen and (min-width: 1360px)`,
    hd: `@media screen and (min-width: 1900px)`,
  },
};
