import endpoint from "shared/endpoint";
import user from "user";
import { settingsAtom } from "../../atoms/settings-atom";

export const uploadFiles = (data: any) => endpoint.post("/uploads", data);
export const getSlider = (name: string) => endpoint.get(`/slider/${name}`);
export const getSearchHistory = () => endpoint.get("/search-histories");

export const getSettings = () => {
  return new Promise(resolve => {
    return endpoint.get("/settings").then(response => {
      settingsAtom.update(response.data.settings);

      resolve(response);
    });
  });
};

export const getCertificates = () => endpoint.get("/certificates");
export const getWorkReason = () => endpoint.get("/work-reasons?paginate=false");

export function getGuestToken() {
  return new Promise(resolve => {
    if (user.getAccessToken()) {
      return resolve({});
    }

    endpoint.post("/login/guests").then(resolve);
  });
}
