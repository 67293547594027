import styled from "@emotion/styled";
import { theme } from "../..";
import { ParagraphProps } from "../types";

export const MainP = styled("p")<ParagraphProps>(
  {
    ...theme.headings.p,
    overflowWrap: "anywhere",
  },
  ({ color, align, fontSize }) => ({
    // color: color ? theme.colors.primary[color] : theme.colors.gray[300],
    textAlign: align || "start",
    fontSize: fontSize || theme.headings.p.fontSize,
  }),
);
