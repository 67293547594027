import React, { useCallback, useEffect, useRef, useState } from "react";
import { BiImageAdd } from "react-icons/bi";
import { ImAttachment } from "react-icons/im";
import { MdDelete } from "react-icons/md";
import { theme } from "../../..";
import { uploadFiles } from "../../../../global/services/GlobalServices";
import { FileInputContainer } from "./styles";

export interface IUploadInputProps {
  setValue: (hash: string) => void;
  required?: boolean;
  attach?: boolean;
  name?: string;
  defaultURL?: string;
  defaultValue?: any;
}

export const UploadInputComp = ({
  setValue,
  required,
  attach,
  name,
  defaultURL,
  defaultValue,
}: IUploadInputProps) => {
  const ref = useRef<HTMLInputElement>(null);
  const [uploaded, setUploaded] = useState<any[]>([]);
  const [hashes, setHashes] = useState(
    defaultValue?.hash
      ? [defaultValue?.hash]
      : defaultValue
      ? [defaultValue]
      : [],
  );

  const onClick = () => ref.current?.click();

  const onChange = useCallback(
    (e: React.ChangeEvent) => {
      const target = e.target as HTMLInputElement;
      const file = target?.files?.[0];
      const formData = new FormData();

      if (file) formData.append("uploads[]", file);

      uploadFiles(formData).then((response: any) => {
        setUploaded(response?.data?.uploads);

        setHashes(response?.data?.uploads?.map((ele: any) => ele?.hash));

        target.value = "";
      });
    },
    [setUploaded, setHashes],
  );

  useEffect(() => {
    if (hashes?.[0]) setValue(hashes?.[0]);
  }, [hashes, setValue]);

  const deleteImage = () => {
    setUploaded([]);
    setHashes([]);
  };

  return (
    <>
      <input
        ref={ref}
        type="file"
        multiple={false}
        onChange={onChange}
        hidden
      />

      <input name={name} type="text" hidden value={hashes?.[0]} />

      <FileInputContainer
        onClick={
          uploaded?.length
            ? onClick
            : () => {
                deleteImage();
                onClick();
              }
        }
        style={{
          backgroundImage:
            uploaded?.length || defaultURL
              ? `url(${uploaded?.[0]?.url || defaultValue?.url || defaultURL})`
              : "",
        }}>
        {uploaded?.length ? (
          <MdDelete
            size={25}
            color={theme.colors.primary.red}
            onClick={deleteImage}
          />
        ) : attach ? (
          <ImAttachment
            size={25}
            color={
              required
                ? theme.colors.primary.red
                : theme.colors.primary.darkBlue
            }
          />
        ) : (
          <BiImageAdd
            size={25}
            color={
              required
                ? theme.colors.primary.red
                : theme.colors.primary.darkBlue
            }
          />
        )}
      </FileInputContainer>
    </>
  );
};
