import { theme } from "../..";
import { Flex } from "../Grid";

export default function InputLabel({ children, required, ...props }: any) {
  if (!children) return null;

  return (
    <label {...props}>
      <Flex alignItems="center">
        {children}
        {required && (
          <strong
            style={{ color: theme.colors.primary.red, lineHeight: "1rem" }}>
            *
          </strong>
        )}
      </Flex>
    </label>
  );
}
