import styled from "@emotion/styled";
import { ContainerType } from "../types";

export const MainContainer = styled("div")({
  margin: "0px auto",
  maxWidth: "1920px",
});

export const ContentContainer = styled("div")({
  maxWidth: "100%",
  margin: "0px auto",
});

export const SectionContainer = styled("div")<ContainerType>(
  {},
  ({ innerContainer }) => ({
    margin: innerContainer ? "0px" : "0px 5.5%",

    "@media screen and (max-width: 500px)": {
      "&.m-sm-0": {
        margin: "0px",
      },
    },
  }),
);

export const FormContainer = styled("div")<ContainerType>(
  {
    margin: "0px auto",
    maxWidth: "800px",
  },
  ({ innerContainer }) => ({
    "@media screen and (max-width: 1181px)": {
      maxWidth: "100%",
      margin: innerContainer ? "0px" : "0px 20px",
      width: innerContainer ? "100%" : "calc(100% - 40px)",
    },
  }),
);

export const FullWidth = styled("div")({
  width: "100%",
});
